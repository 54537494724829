.keyValItem {
	color: rgb(245,245,245);
	height: 100px;
	width: 100%;
	background-color: rgb(20,20,20);
	margin-bottom: 0;
	border: 1px solid #F0F0F0;

}

.scoreWrapperDiv {
        width: 100%;

}


.boxOuterColorBar {
	display:inline-block;
	height: 10px;
    width: 100%;
	margin-left: 10px;
	margin-top: 20px;
	

}

.confidenceScore {

	font-size: 0.75em;
	float: right;
	margin-top: 15px;
	margin-right: 7px;
	margin-left: 10px;
	
	
}

div.dataKeyValue {
	padding-left: 10px;
    font-size: 12px;
}

span {
	//border: 1px solid orange;

}

.keyValueDivider {
        height: 3px;
        width: 50%;
	margin-left: 10px;
	margin-top: 3px;
	margin-bottom: 3px;
	background-color: rgb(64,64,64);
}

input.valDivInput {
	width: 50%;
} 