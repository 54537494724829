.custom-input {
  font-size: 1em;
  border: 1px solid #9B9B9B;
  border-radius: 0 !important;
  border-top: none;
  border-right: none;
  padding: 0.5em;
  margin: 0 0 1em 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
  color: white;
}

.custom-input:focus {
  /* outline-color:#8EF1FF;
  outline-style: solid; */
  outline: none !important;
  border-color: #8EF1FF;
  /* border-width: 2px; */
  /* border-color: black; */
}

.custom-input-label {
  text-align: left;
  background-color: none;
  color: #9B9B9B;
}