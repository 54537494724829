/* @font-face {
    font-family: 'source_sans_proregular';
    src: url("source-sans-pro/sourcesanspro-regular-webfont-d95d3481fcb64b510be5439bcc5efccd.eot");
    src: url("source-sans-pro/sourcesanspro-regular-webfont-d95d3481fcb64b510be5439bcc5efccd.eot?#iefix") format("embedded-opentype"), url("source-sans-pro/sourcesanspro-regular-webfont-c0d1fe69cdce864c31bea52a0d08aa4f.woff2") format("woff2"), url("source-sans-pro/sourcesanspro-regular-webfont-7fb92e2b8aed1232017e0537bb88862d.woff") format("woff"), url("source-sans-pro/sourcesanspro-regular-webfont-930b363a6cd1e9fa80eeed9bb299537e.ttf") format("truetype"), url("source-sans-pro/sourcesanspro-regular-webfont-f2749d9dbe5380bac56413a437ec127f.svg#source_sans_proregular") format("svg");
    font-weight: normal;
    font-style: normal;
} */
/* 
@font-face {
    font-family: 'source_sans_prosemibold';
    src: url("source-sans-pro/sourcesanspro-semibold-webfont-4a3693eb09ca34e031197da1119ff92e.eot");
    src: url("source-sans-pro/sourcesanspro-semibold-webfont-4a3693eb09ca34e031197da1119ff92e.eot?#iefix") format("embedded-opentype"), url("source-sans-pro/sourcesanspro-semibold-webfont-e7b6510a98b9c903933eac5ed17fff8d.woff2") format("woff2"), url("source-sans-pro/sourcesanspro-semibold-webfont-98cd3c32635144ea7b52fe65d2d4bfff.woff") format("woff"), url("source-sans-pro/sourcesanspro-semibold-webfont-6b5661a299a7093f94b71d2f746c4004.ttf") format("truetype"), url("source-sans-pro/sourcesanspro-semibold-webfont-e6fd233c189e6fdeb4ca4862b6c4f0cc.svg#source_sans_prosemibold") format("svg");
    font-weight: normal;
    font-style: normal;
} */
/* 
@font-face {
    font-family: 'source_sans_probold';
    src: url("source-sans-pro/sourcesanspro-bold-webfont-7bae525db515657fdc5a37a76219973d.eot");
    src: url("source-sans-pro/sourcesanspro-bold-webfont-7bae525db515657fdc5a37a76219973d.eot?#iefix") format("embedded-opentype"), url("source-sans-pro/sourcesanspro-bold-webfont-2f493545a2f7ffca9e6b82fcf2f2e70d.woff2") format("woff2"), url("source-sans-pro/sourcesanspro-bold-webfont-82173ab1d32083b69278a46c98099849.woff") format("woff"), url("source-sans-pro/sourcesanspro-bold-webfont-6410bd51d921b88be8afa5c51d7c7360.ttf") format("truetype"), url("source-sans-pro/sourcesanspro-bold-webfont-53d7206b8fad4d936bf22d679ba68f38.svg#source_sans_probold") format("svg");
    font-weight: normal;
    font-style: normal;
} */

body {
    overflow: auto;
    overflow-y: scroll;
    background: #FFFFFF;
    text-shadow: 0 -1px 1px rgba(255, 255, 255, 0.01);
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: #78AACE;
    text-rendering: optimizeLegibility;
    font-family: source_sans_proregular, arial, helvetica, sans-serif;
    color: #666666;
    font-size: 100%;
    line-height: 1.5em;
    padding: 0;
    margin: 0;
}

a {
    cursor: pointer;
    color: #1B94BC;
    text-decoration: none;
    outline: 0;
}

a:focus,
a:active,
a:hover {
    outline: 0;
    text-decoration: none;
}

ul {
    color: #000000;
}

.clear {
    clear: both;
}

.container {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
}

.white-bg {
    background-color: #FFFFFF;
    border: 1px solid #E5E5E5;
    padding-top: 10px;
}

.top-right-links {
    text-align: center;
    margin-top: 7px;
}

.top-right-links a {
    font-family: source_sans_proregular, arial, helvetica, sans-serif;
    color: #303030;
    font-size: 13px;
    line-height: 10px;
    text-decoration: none;
}

/*
.main-header {
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 5px;
    width: 100%;
}

*/
.login-logo {
    text-align: center;
    margin-top: 7px;
}

.call-sale {
    padding: 0;
    margin: 0;
    font-size: 17px;
    color: #dd0202;
    font-family: source_sans_prosemibold, arial, helvetica, sans-serif;
    text-align: center;
    margin: 0 auto;
}

.call-support {
    font-size: 14px;
    color: #000000;
    text-align: right;
}

.toggle {
    display: block;
    padding: 10px;
    color: #FFFFFF;
    text-align: left;
}

.main-nav {
    width: 100%;
    margin: 15px 0 20px;
    text-align: center;
    background: #515151;
    background: -moz-linear-gradient(top, #515151 0%, #484848 50%, #414141 100%);
    background: -webkit-linear-gradient(top, #515151 0%, #484848 50%, #414141 100%);
    background: linear-gradient(to bottom, #515151 0%, #484848 50%, #414141 100%);
}

.login-nav {
    text-align: center;
}

.login-nav ul {
    display: none;
    text-align: left;
    padding: 0;
    margin: 0;
}

.login-nav ul li {
    list-style-type: none;
    padding: 4px 20px 0;
    cursor: pointer;
    height: 29px;
}

.login-nav ul li:hover {
    background-color: #CC0000;
}

.login-nav ul li.main-nav-divider {
    padding: 0;
    height: 1px;
    background-color: #000000;
}

.login-nav ul li.main-nav-divider:hover {
    background-image: none;
}

.login-nav ul li a {
    color: #FFFFFF;
    font-size: 16px;
}

.current-language {
    display: inline-block;
    position: relative;
}

.arrow-right {
    display: inline-block;
    position: relative;
    margin-top: 7px;
    margin-right: 5px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid red;
}

.tab-part {
    position: relative;
    float: none;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
}

.form-group label {
    width: 35%;
}

.login-info-bottom-left {
    width: 0;
}

.login-info-bottom-right {
    width: 100%;
}

.required {
    font-family: source_sans_proregular, arial, helvetica, sans-serif;
    color: #CC0000;
    font-size: 14px;
    line-height: 1.5em;
    text-align: left;
    margin: 8px 0;
}

.checkbox-holder {
    position: relative;
    float: left;
    width: 20px;
    height: 20px;
    width: 20px;
}

.checkbox-holder input[type="checkbox"] {
    padding: 0;
    margin: 0;
}

.login-info-bottom .remember {
    font-family: source_sans_proregular, arial, helvetica, sans-serif;
    color: #666666;
    font-size: 14px;
    line-height: 14px;
    position: relative;
    float: left;
    margin: 5px 0 0 0;
}

.description-part {
    margin-top: 10px;
    padding: 20px;
}

.description-part h2 {
    font-family: source_sans_proregular, arial, helvetica, sans-serif;
    color: #0A75AD;
    font-size: 26px;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 0;
}

.description-content h3 {
    font-family: source_sans_proregular, arial, helvetica, sans-serif;
    color: #303030;
    font-size: 15px;
    line-height: 15px;
}

.description-content li {
    font-family: source_sans_proregular, arial, helvetica, sans-serif;
    color: #000000;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 15px;
}

.forgot-page .common {
    width: 100% !important;
}

.forgot-page .form-group .form-input input[type="text"],
.forgot-page .form-group .form-input input[type="password"] {
    width: 98%;
}

.login-page .form-group .form-input input[type="text"],
.login-page .form-group .form-input input[type="password"] {
    height: 40px;
}

.loginCS-page .form-group .form-input {
    position: relative;
    float: left;
    width: 54%;
}

.loginCS-page .form-group .form-input input[type="text"],
.loginCS-page .form-group .form-input input[type="password"] {
    width: 98%;
}

.unlockAccount .form-group .form-input input[type="text"] {
    width: 98%;
}

.unlockAccount .description-part {
    width: 100%;
}

.login-main-content {
    width: 100%;
}

.accountUnlockSuccess .tab-part {
    position: relative;
    float: none;
    width: 100%;
    padding: 0 50px;
    box-sizing: border-box;
}

.loginCS-page .description-part {
    width: 50%;
}

.bottom-shadow {
    width: 100%;
    height: 28px;
    display: none;
    /* background-image: url(bottom-shadow-cd03767bb394470941d9250501dd1bc1.png); */
    background-repeat: no-repeat;
    background-position: center center;
}

.progressDiv p {
    margin-top: 25px;
}

.cs-secure-login .main-nav {
    display: none;
}

#adminLoginCS .form-group {
    margin-bottom: 0;
}

#adminLoginCS h2 {
    margin: 10px 0 0;
}

#adminLoginCS .required {
    text-align: right;
    margin: 2px 0;
}

#adminLoginCS .required p {
    margin: 0;
}

#adminLoginCS .form-group-label {
    float: none;
    width: 100%;
}

#adminLoginCS .form-group-label .label-text {
    margin-bottom: 0;
}

#adminLoginCS .form-group-label .label-text p {
    margin: 0;
}

#adminLoginCS .form-input {
    float: none;
    width: 100%;
}

#adminLoginCS .form-input input {
    height: auto;
    padding: 8px;
    width: 100%;
}

#adminLoginCS #adminLoginCSButton {
    margin-bottom: 0;
}

#adminLoginCS #formErrorJavascriptMessageloginFormErrors {
    margin-top: 0;
    line-height: 20px;
}

.loginCS-page .description-part {
    width: calc(100% - 40px);
    margin-top: 0;
}

.footer {
    margin: 0 auto 20px;
}

.footer ul li {
    font-family: source_sans_proregular, arial, helvetica, sans-serif;
    color: #444444;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    list-style: none;
    width: 100%;
    background-color: #E5E5E5;
    border-bottom: #FFFFFF 1px solid;
    display: block;
    box-sizing: border-box;
}

.footer ul li a {
    color: #444444;
    display: block;
    padding: 9px;
}

.footer .text-center {
    text-align: center;
    margin: 0 auto;
}

.footer .text-center ul {
    padding: 0;
    margin: 0;
}

.footer .copyright {
    font-size: 12px;
    margin-top: 10px;
}

.footer-img {
    margin: 25px 0 0;
    padding: 0 15px;
}

.footer-img a {
    display: inline-block;
}

.footer-img img {
    display: block;
    width: 100%;
    max-width: 706px;
}

.footer ul li.login-language-selector {
    cursor: pointer;
    margin: 0;
    font-size: 15px;
    display: inline-block;
    padding: 9px;
}

.footer ul li.login-language-selector ul {
    display: none;
    list-style: none;
    position: absolute;
    margin-top: -111px;
    margin-right: auto;
    margin-left: auto;
    border-top: 1px solid #c8c8c8;
    z-index: 1000;
    width: 170px;
    right: 0;
    left: 0;
}

.footer ul li.login-language-selector ul li {
    position: relative;
    width: 100%;
    color: #666;
    text-align: center;
    border-right: none;
    background-color: #7d7d7d;
}

.footer ul li.login-language-selector ul li a {
    padding: 12px 0;
    width: 100%;
    display: block;
    font-size: 15px;
    color: white;
    font-family: source_sans_prosemibold, arial, helvetica, sans-serif;
}

.resp-tabs-list {
    padding: 0;
    margin: 0;
}

.resp-tabs-list li {
    font-size: 15px;
    font-family: source_sans_prosemibold, arial, helvetica, sans-serif;
    display: inline-block;
    padding: 13px 15px;
    margin: 0;
    list-style: none;
    cursor: pointer;
    --float: left;
}

h2.resp-tab-active {
    background-color: #C6E1f9;
    color: #194C77;
}

.resp-tabs-container {
    padding: 0;
    background-color: #FFFFFF;
    clear: left;
}

.fake-user-resp-tab {
    background-color: #1b94bc;
    border: 1px solid #0a75ad;
    color: white;
    cursor: pointer;
    font-family: source_sans_prosemibold, arial, helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.5em;
    margin: 0 0 5px;
    padding: 10px 15px;
    font-weight: normal;
}

.resp-accordion {
    font-family: source_sans_proregular, arial, helvetica, sans-serif;
    color: #FFFFFF;
    font-size: 13px;
    line-height: 1.5em;
    cursor: pointer;
    display: none;
    border: 1px solid #0A75AD;
    margin: 0 0 5px;
    padding: 10px 15px;
    background-color: #1B94BC;
}

.resp-tab-content {
    display: none;
    padding: 15px;
    border: 1px solid #C1C1C1;
}

.resp-tab-content h2 {
    margin: 0;
    font-family: source_sans_proregular, arial, helvetica, sans-serif;
    color: #0A75AD;
    font-size: 24px;
    line-height: 26px;
}

.resp-tab-active {
    border: 1px solid #C1C1C1;
    border-bottom: none;
    margin-bottom: 0 !important;
    padding: 10px 15px;
    background-color: #B2D7F7;
    color: #444444;
}

.resp-tab-active span.resp-arrow {
    border: none;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 12px solid #9B9797;
}

.resp-content-active,
.resp-accordion-active {
    display: block;
}

.resp-tab-title:last-child {
    border-bottom: 12px solid #C1C1C1 !important;
    background: blue;
}

.resp-arrow {
    position: relative;
    float: right;
    width: 0;
    height: 0;
    margin-top: 3px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 12px solid #FFFFFF;
}

.resp-easy-accordion h2.resp-accordion,
.fake-user-resp-tab {
    display: block;
    font-weight: normal;
    font-family: source_sans_probold, arial, helvetica, sans-serif;
    font-size: 15px;
}

.resp-easy-accordion .resp-tab-content {
    border: 1px solid #C1C1C1;
}

.resp-easy-accordion .resp-tab-content:last-child {
    border-bottom: 1px solid #C1C1C1 !important;
}

.resp-jfit {
    width: 100%;
    margin: 0;
}

.resp-tab-content-active {
    display: block;
    margin-bottom: 5px;
    border-top-width: 0 !important;
}

.form-group {
    margin-bottom: 5px;
}

.group-label {
    position: relative;
    padding: 0 10px 0 0;
}

.label-text {
    font-family: source_sans_proregular, arial, helvetica, sans-serif;
    color: #666666;
    font-size: 13px;
    line-height: 14px;
    position: relative;
    margin: 6px 0 4px;
    float: left;
}

.label-asterisk {
    font-family: source_sans_proregular, arial, helvetica, sans-serif;
    color: #CC0000;
    font-size: 15px;
    line-height: 9px;
    position: relative;
}

.digits-text {
    font-family: source_sans_proregular, arial, helvetica, sans-serif;
    color: #9E9E9E;
    font-size: 11px;
    line-height: 12px;
    font-style: italic;
    float: left;
    margin: 8px 0 0 5px;
}

.loginCS-page .form-group label {
    font-family: source_sans_proregular, arial, helvetica, sans-serif;
    color: #666666;
    font-size: 13px;
    line-height: 1.5em;
    position: relative;
    float: left;
    width: 46%;
}

.unlockAccount .form-group label {
    font-family: source_sans_proregular, arial, helvetica, sans-serif;
    color: #666666;
    font-size: 13px;
    line-height: 1.5em;
    position: relative;
    float: left;
    width: 46%;
}

.warning-icon {
    position: relative;
    float: left;
    display: none;
}

.label-text-float {
    display: none !important;
}

.unlockAccount .form-group .form-input {
    position: relative;
    float: left;
    width: 54%;
}

.form-group .form-input input[type="text"],
.form-group .form-input input[type="password"] {
    border: 1px solid;
    border-radius: 0 !important;
    height: 40px;
    padding: 0 10px;
    font-size: 15px;
    width: 100%;
    box-sizing: border-box;
}

.login-page .right-pull-user {
    margin-left: 38%;
}

.loginCS-page .right-pull-user {
    margin-left: 46%;
}

.unlockAccount .right-pull-user {
    margin-left: 46%;
}

.login-info-bottom a {
    display: block;
    margin-top: 0;
    font-family: source_sans_proregular, arial, helvetica, sans-serif;
    color: #CC0000;
    font-size: 14px;
    line-height: 1.5em;
    text-decoration: underline;
}

.login-info-bottom input[type="button"],
#adminForgotPasswordButton,
#adminForgotPasswordCancel,
#userForgotFaxNumberButton,
#userForgotFaxNumberCancel,
#userForgotPasswordButton,
#userForgotPasswordCancel#userForgotPasswordButton {
    width: 95px;
    height: 33px;
    background: #3f87c5;
    background: -moz-linear-gradient(top, #3f87c5 0%, #3173aa 24%, #3173aa 100%, #3f87c5 100%, #3172a9 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #3f87c5), color-stop(24%, #3173aa), color-stop(100%, #3173aa), color-stop(100%, #3f87c5), color-stop(100%, #3172a9));
    background: -webkit-linear-gradient(top, #3f87c5 0%, #3173aa 24%, #3173aa 100%, #3f87c5 100%, #3172a9 100%);
    background: -o-linear-gradient(top, #3f87c5 0%, #3173aa 24%, #3173aa 100%, #3f87c5 100%, #3172a9 100%);
    background: -ms-linear-gradient(top, #3f87c5 0%, #3173aa 24%, #3173aa 100%, #3f87c5 100%, #3172a9 100%);
    background: linear-gradient(to bottom, #3f87c5 0%, #3173aa 24%, #3173aa 100%, #3f87c5 100%, #3172a9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3f87c5', endColorstr='#3172a9', GradientType=0);
    border: 1px solid #0A75AD;
    border-radius: 5px;
    color: #FFFFFF;
    margin: 15px 0 10px;
    display: block;
    cursor: pointer;
    font-family: source_sans_prosemibold, arial, helvetica, sans-serif;
}

#adminForgotPasswordCancel {
    float: left;
    width: auto;
}

#adminForgotPasswordButton {
    float: left;
    margin-left: 10px;
    width: auto;
}
/* 
.form-group span.help-img {
    background-image: url(help-button-3ef99f8af0eb8fead855904d55bd7d9f.png);
    background-repeat: repeat;
    display: inline-block;
    width: 14px;
    height: 15px;
    vertical-align: middle;
} */

.right-pull label.remember {
    position: relative;
    float: right;
    width: 93%;
}

.forgot-page .textBoxPadding {
    padding-left: 35px;
}

.login-page .icon-warning.warningSymbol {
    margin-left: -2px;
    margin-right: 0;
}

.accountUnlockSuccess .right-pull {
    margin-left: 0;
}

#userLoginHelp {
    display: none;
}

.formMessageIcon {
    position: relative;
    float: left;
    width: 40px;
    box-sizing: border-box;
    padding: 0 10px 0 3px;
    margin: 3px 0 0 0;
}

.formMessage {
    position: relative;
    float: left;
    width: calc(100% - 40px);
    margin-top: 4px;
    box-sizing: border-box;
    padding: 0;
    font-family: source_sans_prosemibold, arial, helvetica, sans-serif;
}

.formError,
.formErrorLarge,
.formSuccessLarge {
    font-family: source_sans_prosemibold, arial, helvetica, sans-serif;
    color: #CC0000;
    font-size: 14px;
    line-height: 15px;
    font-weight: normal;
    padding-bottom: 0;
    padding-top: 0;
    text-align: left;
}

.formSuccessLarge {
    color: #008800;
}

.formSuccessLarge {
    margin-bottom: 25px;
}

.formRequired {
    font-family: source_sans_proregular, arial, helvetica, sans-serif;
    color: red;
    font-size: 12px;
    line-height: 1.5em;
}

.formErrorJavascriptTable {
    color: red;
}

.formErrorJavascriptTable,
.formErrorGeneralTable {
    border: 1px solid red;
    margin-bottom: 20px;
    padding: 5px;
    width: 100%;
}

.forgot-pw-tabs {
    padding: 0 20px 20px;
    box-sizing: border-box;
}

.forgot-pw-tabs-left,
.forgot-pw-tabs-right {
    display: none;
}

.forgot-pw-content {
    box-sizing: border-box;
    padding: 0 50px;
    width: 100%;
}

.forgot-pw-right-container {
    margin-top: 30px;
}

.account-sub-heading {
    margin-top: 15px;
    font-family: source_sans_proregular, arial, helvetica, sans-serif;
    color: #666666;
    font-size: 14px;
    line-height: 17px;
}

#userForgotPassword .pinUsernameTrId {
    width: 100%;
}

#phoneContactEmailReqDiv1,
#usernameReqDiv1 {
    font-size: 15px;
    color: #CC0000;
}

.account-sub-heading h3 {
    font-size: 16px;
}

.textfield-heading {
    font-family: source_sans_proregular, arial, helvetica, sans-serif;
    color: #666666;
    font-size: 13px;
    line-height: 14px;
    position: relative;
}

p.forgot-password-description {
    font-family: source_sans_proregular, arial, helvetica, sans-serif;
    color: #666666;
    font-size: 14px;
    line-height: 17px;
    background-image: none;
    margin-left: 0;
    padding: 0;
    line-height: 21px;
}

#adminForgotPin .required p,
#userForgotFaxNumber .required p {
    margin-bottom: 0;
}

.border-warning {
    border: 1px solid red !important;
}

#progressDiv .forgot-pw-left-container input.textInput {
    height: 25px;
}

.unlock-main-container {
    padding: 0 20px 20px;
    position: relative;
}

.unlock-row-1 {
    color: #0A75AD;
    font-size: 24px;
}

.unlock-row-2 {
    color: #666666;
    font-size: 16px;
    margin-top: 15px;
}

.unlock-row-3 {
    color: #666666;
    font-size: 15px;
    margin-top: 15px;
    line-height: 17px;
}

.unlock-row-success-message {
    color: #666666;
    font-size: 15px;
    margin-top: 15px;
    line-height: 17px;
}

.unlock-left-holder {
    position: relative;
}

.unlock-middle-column {
    display: none;
}

.unlock-right-holder {
    position: relative;
    margin: 15px 0 0 0;
}

.unlock-right-holder-btns {
    padding-top: 10px;
}

.unlock-label-1,
.unlock-label-2 {
    color: #0A75AD;
}

.unlock-field {
    margin-bottom: 5px;
}

.unlock-field input {
    width: 100%;
    height: 25px;
    padding: 5px;
    box-sizing: border-box;
}

.unlock-submit-btn,
.unlock-submit-cancel,
.unlock-success-login-btn,
.unlock-success-login-cancel,
.unlock-success-forgot-pass-btn,
.unlock-success-forgot-pass-cancel {
    font-size: 15px;
    color: #FFFFFF;
    text-align: center;
    border-radius: 8px;
    padding: 8px 16px;
    margin: 15px 0 0 0;
    width: 100px;
    cursor: pointer;
    background: #409ed1;
    background: -moz-linear-gradient(top, #409ed1 0%, #367bb5 15%, #3173aa 24%, #3173aa 100%);
    background: -webkit-linear-gradient(top, #409ed1 0%, #367bb5 15%, #3173aa 24%, #3173aa 100%);
    background: linear-gradient(to bottom, #409ed1 0%, #367bb5 15%, #3173aa 24%, #3173aa 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#409ed1', endColorstr='#3173aa', GradientType=0);
}

.unlock-submit-btn:hover {
    background: #409ed1;
    background: -moz-linear-gradient(top, #409ed1 0%, #367bb5 15%, #016da3 24%, #005387 100%);
    background: -webkit-linear-gradient(top, #409ed1 0%, #367bb5 15%, #016da3 24%, #005387 100%);
    background: linear-gradient(to bottom, #409ed1 0%, #367bb5 15%, #016da3 24%, #005387 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#409ed1', endColorstr='#005387', GradientType=0);
}

.unlock-success-login-btn,
.unlock-success-forgot-pass-btn {
    width: 100%;
    box-sizing: border-box;
}

.red-alert {
    color: #CC0000;
}

.off {
    display: none;
}

.recoverPassword .main-nav {
    margin: 0;
}

.recoverPassword .login-main-content {
    width: auto;
    padding: 40px;
}

.recoverPassword h1 {
    margin-top: 0;
}

.recoverPassword h3 {
    font-size: 16px;
    line-height: 18px;
}

.recoverPassword .directions {
    margin-bottom: 20px;
    line-height: 19px;
}

.recoverPassword .error-message {
    margin-bottom: 10px;
    color: red;
}

.recoverPassword #accountId,
.recoverPassword #name {
    width: 200px;
}

.recoverPassword .submit-btn {
    padding: 5px 15px;
    margin: 20px 0 0 0;
    background: transparent linear-gradient(to bottom, #3f87c5 0%, #3173aa 24%, #3173aa 100%, #3f87c5 100%, #3172a9 100%) repeat scroll 0 0;
    border: 1px solid #0a75ad;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    display: block;
    font-family: source_sans_prosemibold, arial, helvetica, sans-serif;
}

.recoverPassword .errorBorder {
    border: 1px solid red;
}

.recoverPassword .reset-text-field-label {
    margin: 4px 0 0;
    position: relative;
}

.recoverPassword .reset-text-field-input {
    margin: 1px 0 0 0;
    position: relative;
}

.recoverPassword .reset-text-field-input input {
    padding: 4px 6px;
    height: auto;
    width: 240px;
}

#cookie-banner-container {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: #edeff5;
    color: #666;
    font-size: 16px;
    padding: 15px 0;
    display: none;
    z-index: 10000;
}

.display-cookie-banner {
    display: block !important;
    width: 100%;
}

#cookie-banner-container:after {
    display: table;
    content: "";
    clear: both;
}

#cookie-banner-text {
    font-size: 16px;
    line-height: 1.25em;
    padding-left: 10px;
    width: 85%;
    float: left;
}

#cookie-banner-button {
    padding-right: 10px;
    float: right;
}

#cookie-understand {
    background-color: #4b81e8;
    border-radius: 4px;
    padding: 7px;
    color: #fff;
    font-size: 16px;
    line-height: 1.5em;
    border: 1px solid #4b81e8;
    cursor: pointer;
}

@media only screen and (min-width: 768px) {
    .top-right-links {
        padding: 10px 20px;
        float: right;
        margin-top: 0;
    }

    .main-header {
        float: left;
        margin-bottom: 5px;
    }

    .login-logo {
        float: left;
        margin-top: 5px;
    }

    .call-sale {
        float: right;
    }

    .sales-telephone {
        margin: 10px 0 0 0;
    }

    .toggle {
        display: none;
    }

    .main-nav {
        margin: 0 0 20px;
        width: 100%;
        height: 40px;
    }

    .nav-list {
        display: block !important;
    }

    .login-nav ul {
        display: block;
       --vertical-align: middle;
    }

    .login-nav ul li {
        padding: 8px 15px 3px;
        float: left;
    }
/* 
    .main-nav ul li.main-nav-divider {
        display: block;
        height: 40px;
        width: 2px;
        padding: 0;
        background-image: url(menu-divider-2-42f250b8ca519fe8649261be7aadd039.png);
    } */

    .arrow-right {
        margin-right: 0;
    }

    #login-nav-end-piece {
        display: block;
    }

    .tab-part {
        position: relative;
        float: left;
        width: 50%;
        padding: 20px 30px 0 30px;
    }

    .label-text {
        float: left;
    }

    .form-group-label {
        position: relative;
        float: left;
        width: 45%;
    }

    .form-group .form-input {
        position: relative;
        float: left;
        width: 55%;
    }

    .user-login-inline-asterisk {
        display: inline;
    }

    #usernameFieldUserReqDiv1 {
        display: none;
    }

    .digits-text {
        float: none;
        margin: 0;
        line-height: 5px;
    }

    .label-text-float {
        display: block !important;
    }

    .fake-user-resp-tab {
        display: none;
    }

    #adminTab {
        width: 25%;
    }

    #fake-user-tab,
    #userTab {
        width: 33%;
    }

    .forgot-pw-tabs #adminTab,
    .forgot-pw-tabs #userTab,
    .forgot-pw-tabs #partnerTab {
        width: 33%;
    }

    #partnerTab {
        width: 25%;
    }

    #developerTab {
        width: 25%;
    }

    #developerTab a {
        color: white;
    }

    #developerTab.resp-tab-active a {
        background-color: #C6E1f9;
        color: #194C77;
    }

    .description-part {
        position: relative;
        float: left;
        width: 50%;
        margin-top: 0;
        box-sizing: border-box;
        padding: 90px 30px 0 30px;
    }

    .description-part h2 {
        font-size: 24px;
        padding: 0;
        margin: 0;
    }

    .login-main-content {
        padding-bottom: 30px;
    }

    .fake-user-tab {
        box-sizing: border-box;
        border: 1px solid #0A75AD;
        border-right: none;
        background-color: #1B94BC;
        color: white;
        text-align: center;
        line-height: normal;
        padding: 13px 15px 10px;
        width: 25%;
        --float: left;
        cursor: pointer;
        display: inline-block;
        font-size: 15px;
        font-family: source_sans_prosemibold, arial, helvetica, sans-serif;
    }

    .fake-user-tab:hover {
        background-color: #0A75AD;
    }

    .resp-tabs-list {
        display: block !important;
    }

    .resp-tabs-list li {
        box-sizing: border-box;
        border: 1px solid #0A75AD;
        border-right: none;
        background-color: #1B94BC;
        color: white;
        text-align: center;
        line-height: normal;
        padding: 13px 15px 10px;
    }

    .resp-tabs-list li:hover {
        background-color: #0A75AD;
    }

    .resp-tabs-list li.resp-tab-active {
        background-color: #C6E1f9;
        color: #194C77;
    }

    .resp-easy-accordion h2.resp-accordion {
        display: none;
    }

    .resp-easy-accordion .resp-tab-content {
        border: 0 solid #1B94BC;
        padding: 15px 0;
    }

    .resp-easy-accordion .resp-tab-content:last-child {
        border-bottom: 0 solid #C1C1C1 !important;
    }

    .resp-tabs-container {
        margin-top: 15px;
    }

    .resp-tabs-list li:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 13px 15px 10px;
    }

    .resp-tabs-list li:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-right: solid 1px #0A75AD;
    }

    .form-group label {
        width: 40%;
    }

    .login-info-bottom-left {
        position: relative;
        float: left;
        width: 45%;
        height: 50px;
    }

    .login-info-bottom-right {
        position: relative;
        float: left;
        width: 55%;
    }

    .login-info-bottom {
        margin-top: 5px;
    }

    .login-info-bottom p {
        font-size: 12px;
        line-height: 12px;
    }

    .login-info-bottom.login-info-bottom label {
        font-size: 12px;
        line-height: 14px;
    }

    .forgot-page .form-group .form-input input[type="text"],
    .forgot-page .form-group .form-input input[type="password"],
    .loginCS-page .form-group .form-input input[type="text"],
    .loginCS-page .form-group .form-input input[type="password"],
    .unlockAccount .form-group .form-input input[type="text"] {
        width: 100%;
    }

    .login-page .form-group .form-input input[type="text"],
    .login-page .form-group .form-input input[type="password"] {
        height: 30px;
        width: 100%;
    }

    .bottom-shadow {
        display: block;
    }

    .required {
        text-align: right;
        margin: 2px 0 5px;
    }

    .cs-secure-login .main-nav {
        display: block;
        margin-bottom: 0;
    }

    .loginCS-page .description-part {
        width: 50%;
        margin-top: 0;
    }

    .footer {
        margin: 10px auto 20px;
        padding: 0 15px;
    }

    .footer ul li {
        width: auto;
        border-right: #444444 1px solid;
        padding-right: 5px;
        padding-left: 5px;
        margin-bottom: 8px;
        display: inline-block;
        background-color: transparent;
        font-family: source_sans_proregular, arial, helvetica, sans-serif;
        color: #444444;
        font-size: 15px;
        line-height: 15px;
        text-align: center;
        list-style: none;
    }

    .footer ul li a {
        color: #444444;
        padding: 0;
        display: inline-block;
    }

    .footer ul li:first-child {
        padding-left: 0;
    }

    .footer ul li:last-child {
        border-right: none;
    }

    .footer .copyright {
        margin-top: 0;
    }

    .footer-img {
        margin: 15px auto 0;
        text-align: center;
    }

    .footer ul li.login-language-selector {
        padding-top: 0;
        padding-bottom: 0;
    }

    .footer ul li.login-language-selector ul {
        margin-top: -120px;
        margin-right: 0;
        margin-left: 0;
        width: 150px;
        right: auto;
        left: auto;
    }

    .footer ul li.login-language-selector ul li {
        margin-bottom: 0;
    }

    .footer ul li.login-language-selector ul li:hover {
        background-color: #5c5c5c;
    }

    .forgot-pw-tabs {
        padding: 20px 30px 0;
        box-sizing: border-box;
    }

    .forgot-pw-tabs-left {
        width: 50%;
        position: relative;
        min-height: 40px;
        box-sizing: border-box;
        float: left;
        display: block;
        padding-right: 30px;
    }

    .forgot-pw-tabs-right {
        width: 50%;
        position: relative;
        min-height: 40px;
        box-sizing: border-box;
        float: left;
        display: block;
        padding-left: 25px;
    }

    .forgot-pw-content {
        box-sizing: border-box;
        padding: 0 50px;
        width: 100%;
    }

    .forgot-pw-left-container {
        position: relative;
        float: left;
        width: 50%;
        padding-right: 30px;
        box-sizing: border-box;
    }

    .forgot-pw-right-container {
        position: relative;
        float: left;
        width: 50%;
        padding-left: 30px;
        box-sizing: border-box;
        margin-top: 0;
    }

    .textfield-heading {
        float: left;
    }

    #adminForgotPin .form-group,
    #userForgotFaxNumber .form-group {
        padding-right: 0;
    }

    #adminForgotPin .form-group label,
    #userForgotFaxNumber .form-group label,
    #userForgotPassword .form-group label {
        position: relative;
        float: left;
        width: 45%;
        padding-right: 10px;
        box-sizing: border-box;
    }

    #adminForgotPin .forgot-pw-text-input,
    #userForgotFaxNumber .forgot-pw-text-input {
        margin-bottom: 5px;
        position: relative;
        float: left;
        width: 55%;
    }

    #adminForgotPin .forgot-pw-text-input input.textInput,
    #userForgotFaxNumber .forgot-pw-text-input input.textInput,
    #userForgotPassword .forgot-pw-text-input input.textInput {
        height: 25px;
    }

    .unlock-main-container {
        width: 50%;
        margin: 50px auto 30px;
    }

    .unlock-left-holder {
        width: calc(50% - 12px);
        position: relative;
        float: left;
    }

    .unlock-middle-column {
        display: block;
        width: 24px;
        height: 24px;
        position: relative;
        float: left;
    }

    .unlock-right-holder {
        width: calc(50% - 12px);
        position: relative;
        float: left;
        margin: 0;
    }

    .unlock-success-login-btn,
    .unlock-success-forgot-pass-btn {
        float: left;
        width: calc(50% - 12px);
    }
}

@media only screen and (min-width: 980px) {
    .label-text {
        font-size: 15px;
    }

    .forgot-pw-tabs {
        padding: 20px 50px 0;
        box-sizing: border-box;
    }
}

@media only screen and (max-width: 875px) {
    .tab-part {
        width: 100%;
    }

    .description-part {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .call-support {
        text-align: center;
    }
}