.value-card {
  background-color: #0E0E0E;
  border-radius: 12px;
  padding: 0.5em;
  margin: 1em 1em 0;
}

.value-card-top {
  flex-wrap: wrap;
}

.value-card-key {
  color: white;
}

.value-card-conf {
  color: #9B9B9B;
  font-size: 12px;
}

.value-card-line {
  border: 1px solid #9B9B9B;
}

.value-card-value {
  color: #51FFC8;
}

.confidence-bar {
  flex: 2;
  margin-right: 0.5em;
}

.user-image {
  height: 20px;
  margin-top: -3px;
  margin-left: 5px;
}

.minimal-input {
  background: none;
  color: white;
  border: none;
  outline: none;
  padding: 3px;
  font-size: 16px;
  width: 100%;
}

.minimal-input:focus {
  outline: 1px solid yellow;
}