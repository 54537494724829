
.App, #root, html, body {
  height: 100%;
  width: 100%;
  
}

#home {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 7fr 2fr;
}

#home header {
  height: 4em;
  display: flex;
  align-items: center;
/*  background: #4A4A4A; */
  z-index: 2;
/*background-color: #F0F0F0; */
/*  background-color: white; */
}

#home header p {
  margin-left: 1em;
  margin-right: 1em;
}

#home aside {
  /* padding: 0.5em; */
  z-index: 2;
  overflow: scroll;
  /* border-left: 1px solid black;
  border-right: 1px solid black; */
}

.header-text {
  font-size: 14px;
  color: black;
  background-color: #F0F0F0;
  
}

#left-aside {
  position: relative;
  background: #F0F0F0;
}

#right-aside {
  position: relative;
/*  width: 300px; */
  width: 100%;
  background-color: white;
/*  border: 1px solid red; */
}

.temphide {
		visibility: hidden;
}
#right-aside footer {
  position: sticky;
  bottom: 0;
  left: 0;
  background: white;

}

.invisible {
  display: none;
}

.image-thumb-container {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  overflow: scroll;
  height: calc(100% - 10em);
  background-color: #F0F0F0;
  color: white;
/*  margin: 0 0 1em; */
  font-weight: bold;
  text-align: center;
  font-size: 10px;
  word-wrap: break-word;
  
}

.image-thumb {
  position: relative;
  width: 100%;
/*  padding-bottom: 100%; */
  margin-bottom: 5px;
  box-sizing: border-box;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  border: 1px solid black;
  background: rgb(20,20,20);
  cursor: pointer;
  color: white;
}

.selected-image-thumb {
  border-color: #8EF1FF;
}

/*
.thumb-number {
 
  color: white;
  margin: 0 0 1em;
  font-weight: bold;
  text-align: center;
  font-size: 10px;
}
*/

.upload-container {
  background-color: #F0F0F0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100%;
  position: absolute;
  bottom: 0;
  width: 100%;
  cursor: pointer;
}

.upload-container img {
  position: absolute;
  top: 50%;
/*  transform: translate(0, -50%) rotate(180deg); */
  width: 35%
}

.no-open-files-upload-container img {
	width: 4%;
	position: absolute;
	top: 30%;
	left: 40%;
	
}

.no-open-files-upload-container img:hover {
		background-color: #e3e1e1;
}

.upload-hint {
	  position: absolute;
	  top: 36%;
	  left: 35%;
	  width: 15%;
	  text-align: center;
	
}

#app-main {
  height: 100%;
  position: relative;
  background: #F0F0F0;
  /* overflow: scroll; */
}

.main-header {
  position: absolute;
  top: 0;
  z-index:2;
  width: 100%;
  
}

.main-header img {
		width: 15%;
		margin-left: 35%;
}

.main-header-login {
  padding: 0 20px;
  box-sizing: border-box;
  margin-bottom: 5px;
  width: 100%;
}


.main-header-login{
  float: left;
  margin-bottom: 5px;
}
.next-page-arrow {
  position: sticky;
  top: 50%;
  left: 100%;
  transform: translate(0, -50%);
  z-index: 2;
  cursor: pointer;
  width: 2em;
  margin-right: 15px;
}

#left-aside-header {
  padding: 0;
}

#left-aside-header img {
  height: 100%;
}

iframe {
  height: 0 !important;
}

.prev-page-arrow {
  position: sticky;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  z-index: 2;
  cursor: pointer;
  width: 2em;
  margin-left: 15px;
}

.aside-header {
  box-sizing: border-box;
  position: sticky;
  top: 0;
/*  width: inherit; */
  width: 100%;
  max-width: inherit;
  justify-content: space-around;
  height: 3em;
  background-color: white;
}

.aside-sub-header {
  position: sticky;
  top: 3em;
  justify-content: space-around;
  height: 3em;
  background-color: rgb(50, 50, 50) !important;
}

.page-number-header {
  position: absolute;
  top: 3em;
  background-color: #F0F0F0;
  /* width: 7.5em; */
}

button.aside-header-tab:hover {

	color: rgb(27,83.136);
	background-color: rgb(17,95,156);
}

button.aside-header-tab:focus {
color: rgb(31,46,112);	
background-color: rgb(187,217,248);

}	


.aside-header-tab {
  padding-left:10px;	
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  height: 75%;
  width: 75px;
  align-items: center;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #F0F0F0;
  background-color: rgb(27,129,172);
  border-radius: 6px;
/* */
display: inline-block; 
background-color: #1E81B0; 
height: 35px; 
width: 100px; 
color: white; 
/* border-radius: 5px; */
background-color:#1D9BF7; 
border:none; 
cursor: pointer; 
box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);

  
}

.credential-form {
  padding: 1em;
}

.custom-button {
  height: 3em;
  width: 100%;
}

#file-input {
  display: none;
}

/*
.selected-aside-header-tab {
  border-radius: 4px;
  border: solid 3px #2a3a8d;
  padding-top: 8px;
  padding-right: 16px;
  padding-bottom: 8px;
  padding-left: 16px;
  font-size: 12px;
  font-weight: 500;
  font-family: "Roboto",sans-serif;
  line-height: 1.17;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  height: 30px;
  border-color: #808080 ;

}
*/

.pdf-view {
  top: 55%;
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% - 6em);
  width: calc(100% - 12em);
  overflow: scroll;
}

.json-container {
  padding: 5px;
  background: rgb(20,20,20);
  width: 100%;
  overflow: scroll;
}

.json-container p {
  color: white;
}

.json-container > ul {
  margin: 0 !important;
  padding: 0.5em !important;
  background-color: #1A1A1A !important;
}

footer {
  position: absolute; 
  bottom: 0;
  width: 100%;
  /* padding: 0 1px 1px 0;
  box-sizing: border-box; */
  /* border: 1px solid rgb(163, 163, 163); */
}

.footer-button:hover {
			color: rgb(27,83.136);
	background-color: rgb(17,95,156);
}

.footer-button {
  width: 100%;
  height: 3em;
  background-color: rgb(27,129,172);
 /*color: #51FFC8; */
  color: white;
  border: 1px solid rgb(144, 144, 144);
  /* outline: 1px solid rgb(163, 163, 163);
  border: 1px solid rgb(163, 163, 163); */
  border-radius: 6px;
}

.green-footer-button {
  width: 100%;
  height: 3em;
  background: rgb(59, 188, 82);
  border: 1px solid rgb(144, 144, 144);
  color: black;
}

.image-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.image-container img {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  width: 100%;
  height: auto;
}

.image-container .image-canvas {
  position: absolute;
  top: 0;
  z-index: 2;
  left: 50%;
  transform: translate(-50%, 0);
}
.adminBox{
  font-family: source_sans_proregular, arial, helvetica, sans-serif !important;
}
h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
/* body {
  overflow: auto;
  overflow-y: scroll;
  background: #FFFFFF;
  text-shadow: 0 -1px 1px rgba(255, 255, 255, 0.01);
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: #78AACE;
  text-rendering: optimizeLegibility;
  font-family: source_sans_proregular, arial, helvetica, sans-serif;
  color: #666666;
  font-size: 100%;
  line-height: 1.5em;
  padding: 0;
  margin: 0;
} */
.container {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.white-bg {
  background-color: #FFFFFF;
  border: 1px solid #E5E5E5;
  padding-top: 10px;
} 

.documentNum {
		color: black;
		font-size: 12px;
}


div.values-container {
		/*border: 2px solid red;*/
		overflow: auto;
		max-height: 1000px;
		margin-right: 20px;
	
}
	