body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* * {
  font-family: 'Anonymous Pro', monospace;
}   */

.flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

#home form input {
  display: block;
}

.rotate-180 {
  transform: rotate(180deg);
}

iframe {
  z-index: 1 !important;
}

button {
  cursor: pointer;
}

.opacity-0 {
   /* opacity: 0;  */
}

*::-webkit-scrollbar {
  display: none;
}